import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'

export default function Info() {
  return (

    <PageTransition
    transitionStyles={{
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    }}
  >
      <div className="wrapper">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Info - Governors Point</title>
          <meta name="description" content="Please email us if you have any questions or would like further information about Governors Point." />
          <link rel="canonical" href="https://governorspoint.com" />
          <body className={isMobile ? `dark mobile` : `dark`} />
        </Helmet>

        <Header menuActive="" />

        <div className="main">

          <div className="container-inner">

            <div className="image-copy-width">
              <p><a href="mailto:info@governorspoint.org">info@governorspoint.org</a></p>
              <p className="mt-60">Additional details</p>
              <ul className="no-list-style">
                <li><a href="/files/Nature Reserve & Trail Map.pdf" rel="noreferrer" target="_blank" className="download">Nature Reserve & Trail Map</a></li>
                <li><a href="https://www.youtube.com/watch?v=qvCiBxunH8Y&t=6s" rel="noreferrer" target="_blank">Whatcom Land Trust Video</a></li>
                <li><a href="/files/Preliminary Plat Map.pdf" rel="noreferrer" target="_blank" className="download">Preliminary Plat Map</a></li>
                <li><a href="https://www.adventuresnw.com/governors-point-finding-harmony-on-the-chuckanut-coast/" rel="noreferrer" target="_blank">Local Article on the project</a></li>
                <li><a href="https://www.seattletimes.com/life/travel/a-food-loverrsquos-tour-of-washingtonrsquos-chuckanut-drive/" rel="noreferrer" target="_blank">Seattle Times on the Area</a></li>
                <li><a href="https://omerarbel.com/projects/aedes/" rel="noreferrer" target="_blank">Architecture Exhibition</a></li>
                <li><a href="https://goo.gl/maps/BH5BQN81wJfRZWEA8" rel="noreferrer" target="_blank">Location</a></li>
              </ul>
            </div>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
